"use client";
import { useGetCurrentUser } from "@/api/user";
import { useTheme } from "next-themes";
import { useEffect } from "react";

const LiveChatScript = () => {
  const theme = useTheme();
  const user = useGetCurrentUser();

  useEffect(() => {
    const script = document.createElement("script");
    script.async = false;
    script.onload = () => {
      // Initialize Plain with your app ID
      // @ts-expect-error "Plain" is not defined
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      Plain.init({
        appId: "liveChatApp_01JAZY7MPA63G3DHRAXQG514ZV",

        // Optional. Hides the launcher, you can manually show it by calling `Plain.open()` (default: false)
        hideLauncher: !!user,

        // Optional. The title of the chat window shown on the Welcome screen
        title: "Chat with us",

        // Optional. A collection of links shown on the Welcome screen
        links: [
          // {
          //   icon: "book", // Optional, we also support 'discord' and 'slack' as icons
          //   text: "View our docs",
          //   url: "https://www.plain.com/docs",
          // },
        ],

        // Optional. The entry point of the Chat, is either 'default' or 'chat'
        // 'chat' will open the last conversation the user had (default: 'default')
        entryPoint: "chat",

        // Optional. The color scheme of the Chat, is either 'auto', 'light', or 'dark'
        // 'auto' uses the user's browser preference to decide between light and dark mode (default: 'auto')
        theme: theme.resolvedTheme === "dark" ? "dark" : "light",

        customerDetails: user.data
          ? {
              fullName: user.data.name, // Optional
              chatAvatarUrl: user.data.avatarURL ?? undefined, // Optional
              email: user.data.email,
              emailHash: user.data.emailHash,
            }
          : undefined,
      });
    };
    script.src = "https://chat.cdn-plain.com/index.js";
    document.head.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [theme.resolvedTheme, user]); // Empty array ensures this effect runs only once

  return null; // This component doesn't render anything
};

export default LiveChatScript;
